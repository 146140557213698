var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"col-12"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","dark":"","fab":"","small":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-checkbox-multiple-blank ")])],1),_vm._v(" Fields List ")],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.fields,"items-per-page":_vm.itemsPerPage,"item-key":"name","search":_vm.search},on:{"click:row":_vm.handleClick,"update:items-per-page":_vm.handleItemsPerPage},scopedSlots:_vm._u([{key:"item.classification",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusButtonClass(item.classification),"dark":""}},[_vm._v(" "+_vm._s(item.classification != 'NewField' ? item.classification : 'New field')+" ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',{staticStyle:{"position":"relative !important","transform":"translateX(4px)"},attrs:{"color":"success"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_c('v-icon',{staticStyle:{"position":"relative !important","transform":"translateX(4px)"},attrs:{"color":"orange"}},[_vm._v(" mdi-clock ")])]}},{key:"item.nbRequests",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.listRequestsStatus),function(requestsStatus){return _c('v-chip',{key:requestsStatus.attribute,attrs:{"color":_vm.statusButtonClass(item.classification),"dark":""}},[_vm._v(" "+_vm._s(item[requestsStatus.attribute])+" ")])})}},{key:"item.nbClients",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.listClientsStatus),function(clientsStatus){return _c('v-chip',{key:clientsStatus.attribute,attrs:{"color":_vm.statusButtonClass(item.classification),"dark":""}},[_vm._v(" "+_vm._s(item[clientsStatus.attribute])+" ")])})}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }