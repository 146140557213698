import Vue from "vue";
import { Component } from "vue-property-decorator";
import { RouteNames } from "@/router";
import { fieldRepository } from "@/repositories";
import { IFieldNbRequests } from "@/models";
import { FieldClassifications } from "@/enums";

@Component({
    name: "fieldsList",
    metaInfo: {
        title: "Manage fields",
    },
})

export default class FieldsList extends Vue {
    public search = "";
    public listRequestsStatus = [
        {
            attribute : "nbRequests",
            text : "active requests",
            class: "black ml-1",
        }
    ];

    public listClientsStatus = [
        {
            attribute : "nbClients",
            text : "clients",
            class: "black ml-1",
        }
    ];

    public loading = false;

    public headers = [
        { text: "Name", value: "name", class: "nowrap", cellClass: "fixedHeight", sortable: true },
        { text: "Code", class: "nowrap", cellClass: "nowrap", value: "code", sortable: true },
        { text: "Classification", value: "classification", cellClass: "text-center" },
        { text: "Clients", value: "nbClients", class: "nowrap", cellClass: "nowrap", sortable: false },
        { text: "Requests", value: "nbRequests", class: "nowrap", cellClass: "nowrap", sortable: false },
        { text: "Active", value: "active", class: "nowrap", cellClass: "nowrap" },
    ];

    public fields: IFieldNbRequests[] = [];

    get itemsPerPage(): number{
        return this.$store.getters["app/itemsPerPage"];
    }

    async mounted(): Promise<void> {
        this.loading = true;
            fieldRepository.getAllWithRequestsNumber().then(res=> {
                this.fields = res;
                this.loading = false;
            }).catch (error => {
            this.loading = false;
            this.$emit("error", error);
        });
    }

    public statusButtonClass(status: string): string {
        switch (status) {
            case FieldClassifications.Green:
                return "success";
            case FieldClassifications.Orange:
                return "warning";
            case FieldClassifications.Red:
                return "error";
            default:
                return "info";
        }
    }

    public handleClick(row: IFieldNbRequests): void {
        this.$router.push({ name: RouteNames.fieldEdit, params: { id: row.id.toString() }});
    }

    public handleItemsPerPage(itemsPerPage: number): void{
        this.$store.dispatch("app/updateItemsPerPage", itemsPerPage);
    }

}
